import React, { JSX } from 'react';

export type ElementProps<T extends React.ElementType> = {
  as?: T,
  identifier?: string,
  children?: React.ReactNode | ((arg?: any) => JSX.Element),
} & React.ComponentPropsWithoutRef<T>;

/**
 * The main purpose of this component is to automate the process of using polymorphic components, i.e., the song and
 * dance seen within the body of this component. We could delete this component and just export types to accomplish
 * the same thing but the tradeoff is adding the code in the body of this component in every polymorphic component.
 *
 * WHY would you even want to do this? Well, in favor of "composition over configuration" this pattern allows us to create
 * composite components that are not bound by configuration. So for instance, we can create a component with several "known"
 * children but only have to focus on the actual props related to the business logic of each of those components. If these
 * children are based on `Element` then the `as` property takes care of making sure that all of the correct "intrinsic props"
 * get set.
 *
 * `Element` is meant to be wrapped (though it can be used independently). When wrapping keep in mind that the wrapped
 * component MUST also implement the proper polymorphic types. So for instance, what that means is, the wrapped component
 * still needs to set up an `as` prop and it needs to manage any "conflicting" component props with intrinsic props
 * (e.g., `className`). To explain further, if your component accepts a prop called `className` (which is also an intrinsic
 * prop) you'll need to omit that from the "intrinsic" props list since at the component level you are saying "we want to
 * control this property name". That is accomplished using something like...
 * `type Props { className: fancyStuff } & Omit<React.ComponentPropsWithoutRef<T>, 'className'>`.
 *
 * If you set the default value of React.ElementType, ALL of the elements must agree intrinsic property wise! If you add
 * a list of elements that don't agree (e.g., `a` | `button`) TS can't properly narrow which type to use. This makes sense
 * when you think about the default use case which is using a "single" polymorphic element. In the default use case TS can
 * easily narrow the intrinsic property list since it is only looking at one element. Here is an example:
 *
 * `type HeadingProps<T extends React.ElementType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'>` = {};
 *
 * Since all of the headings support the same intrinsic element names we can safely narrow this instance to ONLY headings. It
 * would break if we tried to add an `a` or `button` element into that list.
 */
function Element<T extends React.ElementType>({
	as,
	children,
	identifier,
	...delegatedProps
}: ElementProps<T>) {
	const Component = as || 'span';
	return (
		<Component
			identifier={identifier ?? ''}
			{...delegatedProps}
		>
			{typeof children === 'function' ? children() : children}
		</Component>
	);
}

export { Element as default, Element };
